
  @import '/src/shared/styles/_variables.scss';

.container {
  padding: 20px;
  z-index: 1;
  overflow: auto;

  @include desktop {
    padding: 48px 64px;
  }
}

.inner-container {
  margin: 80px 20px;
  display: flex;
  flex-direction: column;

  @include desktop {
    margin: 80px 100px;
  }

  h1 { 
    color: $white;
    font-size: 95px;
    line-height: normal;
  }

  .description {
    padding-bottom: 40px;
    font-size: 64px;
    color: $primary-color5;
    font-family: $giorgio;
    margin: 0;
    line-height: normal;
  }

  ul { 
    list-style-type: disc; 
    list-style-position: inside;
    margin: 6px 0;
  }

  p, li {
    color: $white;
    margin-left: 0;
  }

  p {
    margin: 16px 0;
  }
}

.background {
  background: $black;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;

  .grid {
    position: fixed;
    height: 35%;
    width: 100%;
    left: 0;
    bottom: 0;
    background-image: url("/images/perspective-grid-purple.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
  }
}